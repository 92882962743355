import React from 'react';

import { Graphs, GraphsSkeleton } from 'ui/Graphs';
import { useFetchSellerDashboardStatistics } from 'features/fundraiser';

export interface IMiniStatsProps {
  sellerId: number;
}

const MiniStats: React.FC<IMiniStatsProps> = ({ sellerId }) => {
  const { data: sellerStats, isLoading: isSellerStatsLoading } = useFetchSellerDashboardStatistics(sellerId);
  return isSellerStatsLoading
    ? <GraphsSkeleton isDashboard={ true } />
    : <Graphs graphStats={ sellerStats || [] } isDashboard={ false } testId="mini-stats" />;
};

export default MiniStats;
