/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Box, Flex, Image, Text,
} from '@lce/slice_v2';
import { format, isBefore } from 'date-fns';
import { useTranslation } from '@lce/i18n';
import _isNil from 'lodash/isNil';

import Alarm from './assets/Alarm.svg';
import Calendar from './assets/Calendar.svg';

import {
  FundraiserListItem,
  FundraiserStatus,
  FundraiserType,
} from 'features/fundraiser/types/fundraiser';
import { Seller } from 'features/fundraiser';

export interface IHeaderInfo {
  fundraiser: FundraiserListItem;
  seller?: Seller;
}

const HeaderInfo: React.FC<IHeaderInfo> = ({ fundraiser, seller }) => {
  const [ t ] = useTranslation();
  const hasSeller = !_isNil(seller);

  const startDate = format(new Date(fundraiser.StartDate), 'MMMM dd, yyyy');
  const endDate = !_isNil(fundraiser.EndDate) && isBefore(new Date(fundraiser.StartDate), new Date(fundraiser.EndDate))
    ? format(new Date(fundraiser.EndDate), 'MMMM dd, yyyy')
    : undefined;
  const hasEndDate = !_isNil(endDate);
  const finalOrderDate = fundraiser.FinalOrderDetails && !_isNil(fundraiser.FinalOrderDetails.FinalOrderDate)
    ? new Date(fundraiser.FinalOrderDetails.FinalOrderDate)
    : undefined;
  const hasFinalOrderDate = !_isNil(finalOrderDate);
  const cancellationDate = format(new Date(fundraiser.CancellationDate || 0), 'MMMM dd, yyyy');

  const status = FundraiserStatus[fundraiser.FundraiserStatus];
  const isFundraiserActive = status === FundraiserStatus[FundraiserStatus.Active];
  const isBrochureFundraiser = fundraiser.FundraiserType === FundraiserType.Brochure;
  const isBrochureFundraiserAndChairperson = isBrochureFundraiser && !hasSeller;
  const showDaysLeft = isBrochureFundraiserAndChairperson && hasFinalOrderDate && isFundraiserActive;
  const finalDay = new Date(finalOrderDate || endDate || 0);
  const daysLeft = Math.floor((finalDay.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));

  return (
    <Flex data-testid="header-info" sx={ { flexDirection: 'column', gap: '8px' } }>
      <Box>
        { isBrochureFundraiser && (
          <Text sx={ { color: 'black' } } variant="dashboard.info">
            { t('dashboard.header.status', { status }) }
          </Text>
        )}
        <Flex sx={ { gap: '8px', alignItems: 'center', height: '24px' } }>
          <Image height="18px" src={ Calendar } width="18px" />
          <Text sx={ { lineHeight: 1 } } variant="dashboard.info">
            { hasEndDate ? t('dashboard.header.runTime', { startDate, endDate }) : startDate }
          </Text>
        </Flex>
        { fundraiser.CancellationDate && (
          <Text variant="dashboard.info">
            { t('dashboard.header.cancellationDate', { date: cancellationDate }) }
          </Text>
        )}
        <Text variant="dashboard.info">
          { hasSeller
            ? t('dashboard.header.fundraiserId', { fundraiserId: fundraiser.Id })
            : t('dashboard.header.fundraiserAndGroupIds', { fundraiserId: fundraiser.Id, groupId: fundraiser.GroupId })}
        </Text>
        { !hasSeller && fundraiser.SalesAgentName && (
          <Text variant="dashboard.info">
            { t('dashboard.header.salesAgent', { name: fundraiser.SalesAgentName }) }
          </Text>
        )}
      </Box>
      { showDaysLeft && (
        <Flex sx={ { gap: '8px', alignItems: 'center', height: '24px' } }>
          <Image height="20px" src={ Alarm } width="20px" />
          <Text sx={ { color: 'black', lineHeight: 1 } } variant="dashboard.info">
            { t('dashboard.header.daysLeft', { daysLeft }) }
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default HeaderInfo;
