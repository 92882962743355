import React from 'react';
import {
  Image, Flex, Text,
  Box,
} from '@lce/slice_v2';

import { IFeaturedProductCard } from '../types';

import { TopArchClipPathSvg } from 'ui/components/arch-clip-path-svgs';
import { RouterLink } from 'ui/common';

export const FeaturedProductCard: React.FC<IFeaturedProductCard & { gridArea: string }> = ({
  imageSrc,
  name,
  description,
  url,
  gridArea,
}) => (
  <Box data-testid="featured-product-card" sx={ { backgroundColor: 'white', gridArea: gridArea } }>
    <RouterLink
      data-testid="featured-product-image-link" sx={ { width: '100%' } } to={ url }
    >
      <Image
        src={ imageSrc }
        sx={ {
          width: '100%',
          height: '215px',
          objectFit: 'cover',
        } }
      />
    </RouterLink>
    <TopArchClipPathSvg />
    <Flex
      sx={ {
        backgroundColor: 'white',
        clipPath: 'url(#top-arch-clip)',
        flexDirection: 'column',
        gap: '8px',
        mt: '-18px',
        p: '24px 16px 16px',
        textAlign: 'left',
      } }
    >
      <RouterLink
        data-testid="featured-product-title-link"
        sx={ {
          color: [ 'primaryOrange', 'black' ],
          textDecoration: 'none',
          ':hover, :focus': {
            textDecoration: [ 'none', 'underline' ],
          },
          height: [ 'auto', 'auto', '40px' ],
          alignContent: 'center',
        } } to={ url }
      >
        <Text variant="home.card.title">
          { name }
        </Text>
      </RouterLink>
      <Text variant="home.card.description">
        { description }
      </Text>
    </Flex>
  </Box>
);

