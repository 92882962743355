import { useQuery } from 'react-query';

import { fetchExistingCoupons } from '../api';
import { ExistingCoupon } from '../types';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';

export const useFetchExistingCoupons =
 (fundraiserId: number) => useQuery<ExistingCoupon[], ApiError>(`${ QueryKeys.ExistingCoupons }-${ fundraiserId }`,
   () => fetchExistingCoupons(fundraiserId),
   {
     retry: false,
     refetchOnWindowFocus: false,
   });
