import React from 'react';
import { Box, Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { format } from 'date-fns';

import { DeliveryInfoBR } from 'features/fundraiser/types/fundraiser';

export type DeliveryInfoProps = {
  details: DeliveryInfoBR;
  isEdit?: boolean;
  testId?: string;
}

export const dataTestIdDeliveryInfo = 'delivery-info-component';

export const DeliveryInfo: React.FC<DeliveryInfoProps> = ({
  testId = dataTestIdDeliveryInfo, details, isEdit = false,
}) => {
  const {
    DeliveryName, DeliveryAddress, DeliveryCity, DeliveryState, DeliveryZipCode,
    DeliveryPhoneNumber, PreferredDeliveryDate, DeliveryTimeRange,
  } = details;
  const [ t ] = useTranslation();
  const deliveryDate = format(new Date(PreferredDeliveryDate), 'MM/dd/yyyy');
  const textStyles = { fontSize: isEdit ? '14px' : '16px' };

  return (
    <Box data-testid={ testId } sx={ { mt: isEdit ? '30px' : 'none', mb: '16px' } }>
      <Text sx={ { mb: isEdit ? '12px' : 'none' } } variant="text.header.form">
        {t('fundraiser.summary.deliveryInfo.title')}
      </Text>
      <Text sx={ textStyles }>
        {DeliveryName}
      </Text>
      <Text sx={ textStyles }>
        {DeliveryAddress}
      </Text>
      <Text sx={ textStyles }>
        {`${ DeliveryCity }, ${ DeliveryState } ${ DeliveryZipCode }`}
      </Text>
      <Text sx={ textStyles }>
        {t('fundraiser.summary.deliveryInfo.deliveryPhone', { phone: DeliveryPhoneNumber })}
      </Text>
      <Text sx={ textStyles }>
        {t('fundraiser.summary.deliveryInfo.deliveryDate', { date: deliveryDate })}
      </Text>
      <Text sx={ textStyles }>
        {t('fundraiser.summary.deliveryInfo.deliveryTimeRange', { range: DeliveryTimeRange }) }
      </Text>
    </Box>
  );
};
