import { useQuery } from 'react-query';

import { fetchFinalOrderSummary } from '../api';
import { FinalOrderSummary } from '../types/fundraiser';
import * as QueryKeys from '../../../constants/QueryKeys';

import { ApiError } from 'features/common';

export const useFetchFinalOrderSummary =
  (id: number) => useQuery<FinalOrderSummary, ApiError>(`${ QueryKeys.FinalOrderSummary }-${ id }`,
    () => fetchFinalOrderSummary(id), {
      retry: false, refetchOnWindowFocus: false, cacheTime: 0, enabled: id > 0,
    });
