/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import {
  Box, Flex, Text, Image,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import numeral from 'numeral';
import { format } from 'date-fns';

import {
  FundraiserPopover as FundraiserPopoverForDesktop,
  NewFundraiserCPPopover, NewFundraiserSellerPopover,
} from '../Popovers';
import { FundraiserOptionsModal as FundraiserOptionsModalForMobile } from '../FundraiserOptionsModal';
import { GearIcon } from '../Popovers/FundraiserPopover';

import { RelationshipIndicator } from './RelationshipIndicator';

import { CancelFundraiserModal } from 'ui/fundraiser';
import {
  FundraiserListItem, FundraiserRelationship, FundraiserState,
} from 'features/fundraiser/types/fundraiser';
import DefaultFundraiserImage from 'assets/images/default-fundraiser-image.png';
import { RouterLink } from 'ui/common';
import { UserInfo } from 'features/common/types/userInfo';
import { useFundraiserDispatch } from 'features/fundraiser/context/fundraiserContext';
import { FundraiserPopover } from 'features/popovers/types';
import { getFundraiserLinkPath } from 'features/fundraiser/utils';

export interface IFundraiserListItemProps {
  fundraiser: FundraiserListItem;
  user: UserInfo | undefined;
  fundraiserState: FundraiserState; // equivallent to fundraiser tab state (active, upcoming, completed, cancelled)
  isCancelFundraiserDefault?: boolean;
  isFundraiserOptionsDefault?: boolean;
  setPopovers: React.Dispatch<React.SetStateAction<FundraiserPopover[]>>;
  popovers: FundraiserPopover[];
}

const FundraiserListItemComponent: React.FC<IFundraiserListItemProps> =
  ({
    fundraiser,
    user,
    fundraiserState,
    popovers,
    setPopovers,
    isCancelFundraiserDefault = false,
    isFundraiserOptionsDefault = false,
  }) => {
    const [ t ] = useTranslation();
    const fundraiserDispatch = useFundraiserDispatch();
    const chairPersonRole = fundraiser.Relationships.includes(FundraiserRelationship.CHAIRPERSON);
    const sellerRole = fundraiser.Relationships.includes(FundraiserRelationship.SELLER);
    const superAdminRole = fundraiser.Relationships.includes(FundraiserRelationship.SUPERADMIN);
    const salesAgentRole = fundraiser.Relationships.includes(FundraiserRelationship.SALESAGENT);
    const iscRole = fundraiser.Relationships.includes(FundraiserRelationship.ISC);

    const canCancelFundraiser = salesAgentRole || superAdminRole;
    const canViewFundraiserType = iscRole || salesAgentRole || superAdminRole;

    const [ popoverReference, setPopoverReference ] = useState<HTMLElement | null>(null);
    const [ isCancelFundraiser, setIsCancelFundraiser ] = useState<boolean>(isCancelFundraiserDefault);
    const [ isFundraiserOptions, setIsFundraiserOptions ] = useState<boolean>(isFundraiserOptionsDefault);

    const handleCancelFundraiserModal = () => {
      setIsCancelFundraiser(!isCancelFundraiser);
    };

    const handleFundraiserOptionsModal = () => {
      setIsFundraiserOptions(!isFundraiserOptions);
    };

    const fundraiserLinkPath = getFundraiserLinkPath(fundraiser, user);

    return (
      <Flex data-testid={ `fundraiser-list-item-${ fundraiser.Id }` } variant="cards.fundraiserList.item">
        {/* seller popover */}
        { sellerRole && fundraiserState === FundraiserState.ActiveUpcoming && (
          <NewFundraiserSellerPopover
            fundraiserId={ fundraiser.Id }
            popovers={ popovers }
            referenceElement={ popoverReference }
            setPopovers={ setPopovers }
          />
        ) }
        {/* chairperson popover */}
        { chairPersonRole && fundraiserState === FundraiserState.ActiveUpcoming && (
          <NewFundraiserCPPopover
            fundraiserId={ fundraiser.Id }
            popovers={ popovers }
            referenceElement={ popoverReference }
            setPopovers={ setPopovers }
          />
        )}
        <Box>
          <RouterLink
            key={ fundraiser.Id }
            sx={ { width: '100%' } }
            to={ fundraiserLinkPath }
          >
            <Flex
              data-testid={ `fundraiser-list-item-click-${ fundraiser.Id }` }
              onClick={ () => fundraiserDispatch({ type: 'SET_FUNDRAISER_ID', fundraiserId: fundraiser.Id }) }
              sx={ { flexDirection: [ 'column', 'row', 'row' ] } }
            >
              <Flex
                sx={ {
                  width: [ '100%', '200px', '300px' ],
                  alignItems: 'center',
                  height: [ '100%', '173px' ],
                } }
              >
                <Image
                  src={ fundraiser.FundraiserImageSource || DefaultFundraiserImage }
                  sx={ {
                    objectFit: 'contain',
                    width: [ '100%' ],
                    height: '100%',
                  } }
                />
              </Flex>
              <Box
                ref={ setPopoverReference }
                sx={ { marginLeft: [ '0px', '20px' ], marginTop: [ '20px', '0px' ], flex: 1 } }
              >
                <Text variant="text.fundraiserListItem.name">
                  {fundraiser.Name}
                </Text>
                <Text>
                  {`Fundraiser ID: ${ fundraiser.Id }`}
                </Text>
                <Box sx={ { marginTop: '20px' } }>
                  <Text variant="text.fundraiserListItem.description">
                    Start Date:
                    {' '}
                    { format(new Date(fundraiser.StartDate), 'MM/dd/yyyy') }
                  </Text>
                  <Text variant="text.fundraiserListItem.description">
                    End Date:
                    {' '}
                    { format(new Date(fundraiser.EndDate), 'MM/dd/yyyy') }
                  </Text>
                  <Text variant="text.fundraiserListItem.description">
                    Dollar Goal:
                    {' '}
                    {numeral(fundraiser.DollarGoal).format(t('currency.dollarGoal'))}
                  </Text>
                </Box>
                <Flex>
                  { chairPersonRole &&
                    <RelationshipIndicator label="CP" />}
                  { sellerRole &&
                    <RelationshipIndicator label="S" />}
                  { iscRole &&
                    <RelationshipIndicator label="ISC" />}
                  {canViewFundraiserType &&
                    <RelationshipIndicator label={ fundraiser.FundraiserType } />}
                </Flex>
              </Box>
            </Flex>
          </RouterLink>
        </Box>
        <Flex sx={ { ml: 'auto', alignItems: 'flex-end' } }>
          { canCancelFundraiser && (
            <>
              <FundraiserPopoverForDesktop
                canCancel={ canCancelFundraiser }
                fundraiserName={ fundraiser.Name }
                setIsCancelFundraiser={ handleCancelFundraiserModal }
              />
              <GearIcon
                data-testid="mobile-gear-icon-button"
                onClick={ handleFundraiserOptionsModal }
                sx={ {
                  cursor: 'pointer',
                  display: [ 'block', 'none', 'none' ],
                } }
              />
            </>
          )}

          { isFundraiserOptions && (
            <FundraiserOptionsModalForMobile
              canCancel={ canCancelFundraiser }
              data-testid="fundraiser-options-modal"
              fundraiserId={ fundraiser.Id }
              fundraiserName={ fundraiser.Name }
              onClose={ handleFundraiserOptionsModal }
              setIsCancelFundraiser={ handleCancelFundraiserModal }
            />
          )}

          {isCancelFundraiser && (
            <CancelFundraiserModal
              data-testid="cancel-fundraiser-modal"
              fundraiserId={ fundraiser.Id }
              fundraiserName={ fundraiser.Name }
              onClose={ handleCancelFundraiserModal }
            />
          )}
        </Flex>
      </Flex>
    );
  };

export default FundraiserListItemComponent;
