import React from 'react';
import {
  Image, Text, Flex, Box,
} from '@lce/slice_v2';

import BeachCaesar from 'assets/images/caesar-beach.svg';

export interface IFundraiserStartCaesarProps {
  message: string;
}

const FundraiserStartCaesar: React.FC<IFundraiserStartCaesarProps> = ({ message }) => (
  <Flex
    sx={ {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      px: [ '15px', '40px' ],
    } }
  >
    <Box>
      <Image alt="Little Caesar on Beach" src={ BeachCaesar } title="Little Caesar on Beach" />
    </Box>
    <Text
      sx={ {
        color: 'black',
        maxWidth: '400px',
        textAlign: 'center',
        fontSize: [ '16px', '20px' ],
      } }
      variant="text.h2"
    >
      {message}
    </Text>
  </Flex>
);

export default FundraiserStartCaesar;
