export default {
  primary: '#FF6000',
  primaryOrange: '#FF6000',
  primaryGrayLight: '#C4C4C4',
  primaryGrayMid: '#EEEEEE',
  primaryGrayDark: '#868686',
  secondaryGreenDark: '#028243',
  secondaryGreenLight: '#28c76f',
  secondaryBlue: '#005EFF',
  secondaryPurple: '#913fe5',
  secondaryRed: '#B60707',
  black: '#000',
  white: '#FFF',
  red: '#ff0000',
};
