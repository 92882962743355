import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { Container, Flex } from '@lce/slice_v2';
import { isAfter, isSameDay } from 'date-fns';

import { useFetchJoinFundraiserValidation } from 'features/fundraiser/hooks/useFetchJoinFundraiserValidation';
import { useJoinFundraiser, useCreateFundraiserSeller, useFetchFundraiserByGuid } from 'features/fundraiser';
import { JoinFundraiserInfoCard } from 'ui/fundraiser/JoinFundraiserInfoCard';
import { JoinFundraiserInfoCardSkeleton } from 'ui/fundraiser/JoinFundraiserInfoCard/JoinFundraiserInfoCardSkeleton';
import FundraiserLogin from 'ui/checkout/Login/FundraiserLogin';
import { FundraiserStatus } from 'features/fundraiser/types/fundraiser';

interface MatchParams {
  fundraiserGuid: string;
}

type JoinFundraiserProps = RouteComponentProps<MatchParams>;

// eslint-disable-next-line max-lines-per-function
const JoinFundraiser: React.FC<JoinFundraiserProps> = ({ match }) => {
  const [ enableValidateCall, setEnableValidateCall ] = useState<boolean>(false);
  const [ isSellerRoleAdded, setIsSellerRoleAdded ] = useState(false);
  const [ checkingUserStatus, setCheckingUserStatus ] = useState(true);

  const history = useHistory();

  const { authState } = useOktaAuth();
  const { setJoinFundraiserGuid, removeFundraiserGuid } = useJoinFundraiser();
  const {
    data: fundraiser, error: fundraiserError, isSuccess: isFundraiserSuccess, isLoading: isFundraiserLoading,
  } = useFetchFundraiserByGuid(match.params.fundraiserGuid);

  const { data: fundraiserValidation } =
    useFetchJoinFundraiserValidation(enableValidateCall, match.params.fundraiserGuid);
  const { mutateAsync: createFundraiserSeller, isLoading } = useCreateFundraiserSeller();

  const isCanceled = useMemo(() => {
    const cancelationDate = fundraiser?.CancellationDate && new Date(fundraiser?.CancellationDate);
    const today = new Date();
    const isCanceledToday = cancelationDate && isSameDay(today, cancelationDate);
    const isAfterCancelationDate = cancelationDate && isAfter(today, cancelationDate);
    const isCanceledByDate = isCanceledToday || isAfterCancelationDate;

    return fundraiser?.FundraiserStatus === FundraiserStatus.Canceled || isCanceledByDate;
  }, [ fundraiser ]);

  useEffect(() => {
    if (fundraiser?.IsExpired || isCanceled) {
      removeFundraiserGuid();
      history.push('/landing/expiredfundraiser');
    }
  }, [ fundraiser, removeFundraiserGuid, history, isCanceled ]);

  useEffect(() => {
    setJoinFundraiserGuid(match.params.fundraiserGuid);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authState.isAuthenticated) {
      setEnableValidateCall(true);
    }
  }, [ authState.isAuthenticated ]);

  useEffect(() => {
    async function addSellerRole() {
      const isFundraiserSellerCreated = await createFundraiserSeller(match.params.fundraiserGuid);
      if (isFundraiserSellerCreated) {
        setIsSellerRoleAdded(isFundraiserSellerCreated);
      }
    }

    if (fundraiserValidation) {
      setEnableValidateCall(false);
    }

    if (fundraiserValidation && fundraiserValidation.IsUserAlreadyInFundraiser) {
      removeFundraiserGuid();
    }

    if (fundraiserValidation &&
        !fundraiserValidation.IsUserAlreadyInFundraiser &&
        !fundraiserValidation.IsInvalidFundraiser &&
        !isLoading &&
        !isSellerRoleAdded) {
      removeFundraiserGuid();
      setCheckingUserStatus(false);
      addSellerRole();
    } else {
      setCheckingUserStatus(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ fundraiserValidation ]);

  if (fundraiserError) {
    return <Redirect to="/" />;
  }

  if (isFundraiserSuccess && !fundraiser?.Active) {
    removeFundraiserGuid();
    return <Redirect to="/" />;
  }

  if (isSellerRoleAdded) {
    return <Redirect to="/dashboard/fundraisers" />;
  }

  return (
    <Container sx={ { mt: [ 0, 0, '70px' ], mb: '70px' } }>
      <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ], alignItems: [ 'center', 'center', 'initial' ] } }>
        { isFundraiserLoading || !fundraiser || checkingUserStatus
          ? <JoinFundraiserInfoCardSkeleton />
          : (
            <>
              <JoinFundraiserInfoCard fundraiser={ fundraiser } />

              {fundraiserValidation?.IsUserAlreadyInFundraiser ? <Redirect to="/dashboard/fundraisers" /> : null}

              {fundraiserValidation?.IsInvalidFundraiser ? <Redirect to="/404" /> : null}

              {fundraiserValidation?.IsUserNotAuthorizedToRegister ? <Redirect to="/403" /> : null}

              { authState !== undefined && !authState.isAuthenticated && (
                <FundraiserLogin showImage={ false } sx={ { mt: '34px', maxWidth: 'none' } } />
              )}
            </>
          ) }
      </Flex>

    </Container>
  );
};

export default JoinFundraiser;
