import * as React from 'react';
import { Flex, Skeleton } from '@lce/slice_v2';

export interface IGraphsSkeleton {
  isDashboard: boolean;
}

export const GraphsSkeleton: React.FC<IGraphsSkeleton> = ({ isDashboard }) => {
  if (isDashboard) {
    return <DashboardGraphsSkeleton />;
  } else {
    return <NotDashboardGraphsSkeleton />;
  }
};

const NotDashboardGraphsSkeleton: React.FC = () => {
  const gap = [ '16px', '32px' ];
  const minWidth = [ 'none', '404px' ];
  const flexWrap = [ 'wrap', 'nowrap' ];
  const size = [ '112px', '124px' ];

  return (
    <Flex
      data-testid="not-dashboard-graphs-skeleton"
      sx={ {
        flexWrap, gap, justifyContent: 'center', minWidth,
      } }
    >
      <Skeleton count={ 1 } sx={ { height: size, width: size, borderRadius: '50%' } } />
      <Skeleton count={ 1 } sx={ { height: size, width: size, borderRadius: '50%' } } />
      <Skeleton count={ 1 } sx={ { height: size, width: size, borderRadius: '50%' } } />
    </Flex>
  );
};

const DashboardGraphsSkeleton: React.FC = () => {
  const gap = [ '22px', '22px', '22px', '45px' ];
  const minWidth = [ 'none', 'none', 'none', '755px' ];
  const minWidthEven = [ 'none', '332px', '332px', '355px' ];
  const flexWrap = [ 'wrap', 'wrap', 'wrap', 'nowrap' ];
  const size = '155px';

  return (
    <Flex
      data-testid="dashboard-graphs-skeleton"
      sx={ {
        flexWrap, gap, justifyContent: 'center', minWidth,
      } }
    >
      <Flex
        sx={ {
          flexWrap: [ 'wrap', 'nowrap' ], gap, justifyContent: 'center', minWidth: minWidthEven,
        } }
      >
        <Skeleton count={ 1 } sx={ { height: size, width: size, borderRadius: '50%' } } />
        <Skeleton count={ 1 } sx={ { height: size, width: size, borderRadius: '50%' } } />
      </Flex>
      <Flex
        sx={ {
          flexWrap: [ 'wrap', 'nowrap' ], gap, justifyContent: 'center', minWidth: minWidthEven,
        } }
      >
        <Skeleton count={ 1 } sx={ { height: size, width: size, borderRadius: '50%' } } />
        <Skeleton count={ 1 } sx={ { height: size, width: size, borderRadius: '50%' } } />
      </Flex>
    </Flex>
  );
};
