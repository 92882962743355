import React, { useRef, useState } from 'react';
import { Button, Flex, Text } from '@lce/slice_v2';
import { Info } from 'react-feather';
import { ThemeUIStyleObject } from 'theme-ui';

import { TooltipPopover } from 'ui/fundraiser';
import { PopupBorder } from 'ui/fundraiser/DashboardStep/StepPopupContent';

export type TextWithInlinePopoverButtonProps = {
  text: string;
  popoverText: string;
  testId?: string;
  textVariant?: string;
  iconHeight?: number;
  sx?: ThemeUIStyleObject;
}

export const dataTestIdTextWithInlinePopoverButton = 'text-with-inline-popover-button-component';

export const TextWithInlinePopoverButton: React.FC<TextWithInlinePopoverButtonProps> = ({
  text,
  popoverText,
  testId = dataTestIdTextWithInlinePopoverButton,
  textVariant = 'popover',
  iconHeight = 16,
  sx,
}) => {
  const [ visible, setVisible ] = useState(false);
  const popoverRef = useRef<HTMLButtonElement>(null);
  const buttonHeight = iconHeight * 1.5;

  return (
    <Flex data-testId={ testId } sx={ sx }>
      <Text sx={ { textWrap: 'balance' } } variant={ textVariant }>
        {text}
        <Button
          data-testid={ `${ testId }-info-button` }
          onClick={ () => setVisible(!visible) }
          ref={ popoverRef }
          sx={ { height: `${ buttonHeight }px`, verticalAlign: 'middle', ml: '8px' } }
          variant="plainText"
        >
          <Info color="#5f6368" size={ iconHeight } strokeWidth={ 2.5 } />
        </Button>
      </Text>
      <TooltipPopover referenceElement={ popoverRef } setVisible={ setVisible } visible={ visible }>
        <PopupBorder>
          <Text variant="popover">
            {popoverText}
          </Text>
        </PopupBorder>
      </TooltipPopover>
    </Flex>
  );
};
