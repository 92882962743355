import React from 'react';
import _every from 'lodash/every';
import _isNil from 'lodash/isNil';
import { useTranslation } from '@lce/i18n';
import { Text, Box } from '@lce/slice_v2';
import numeral from 'numeral';
import { format } from 'date-fns';

import { DeliveryInfo } from '../../DeliveryInfo';

import { FundraiserInfo } from 'features/fundraiser';
import { FundraiserProfitCheckAddress } from 'features/fundraiser/types/fundraiser';
import { useSafeDateFormat } from 'ui/hooks';

export interface IFundraiserDetailsSummaryProps {
  profitCheckInfo?: FundraiserProfitCheckAddress;
  details: FundraiserInfo | undefined;
  isBrochure?: boolean;
  isMealDeal?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const FundraiserDetailsSummary: React.FC<IFundraiserDetailsSummaryProps> = ({
  profitCheckInfo, details, isBrochure, isMealDeal,
}) => {
  const endDate = useSafeDateFormat(details?.EndDate, 'MM/dd/yyyy');
  const hasEndDate = endDate !== 'unknown';
  const [ t ] = useTranslation();
  if (details?.StartDate.toString().length === 10) {
    details.StartDate = new Date(`${ details.StartDate }T00:00:00`);
  }
  if (hasEndDate && details?.EndDate.toString().length === 10) {
    details.EndDate = new Date(`${ details.EndDate }T00:00:00`);
  }
  const profitCheckKeys = Object.keys(profitCheckInfo || {});
  // eslint-disable-next-line max-len
  const profitCheckInfoCheck = _every(profitCheckKeys, key => profitCheckInfo && profitCheckInfo[key] && profitCheckInfo[key] !== '');

  return (
    <Box data-testid="fundraiser-details-summary">
      <Text variant="text.header.form">
        {t('fundraiser.Organization')}
      </Text>
      <Text>
        {details?.OrgName}
      </Text>
      <Text>
        Organization Type:
        {' '}
        {details?.GroupTypeName}
      </Text>
      <Text>
        Start Date:
        {' '}
        { format(new Date(details?.StartDate || 0), 'MM/dd/yyyy')}
        {' '}
      </Text>
      {hasEndDate && (
        <Text>
          End Date:
          {' '}
          {endDate}
          {' '}
        </Text>
      )}
      {isBrochure && details?.DeliveryInfo && (
        <Box sx={ { mt: '30px' } }>
          <DeliveryInfo details={ details.DeliveryInfo } />
        </Box>
      )}
      {isMealDeal && (
        <>
          <Text sx={ { mt: '30px' } } variant="text.header.form">
            Check Payable To
          </Text>
          {profitCheckInfoCheck && (
            <>
              <Text>
                {profitCheckInfo?.profitCheckPayableTo}
              </Text>
              <Text>
                { profitCheckInfo?.groupAddress }
              </Text>
              <Text>
                {`${ profitCheckInfo?.groupCity } ${ profitCheckInfo?.groupState }, ${ profitCheckInfo?.groupZipCode }`}
              </Text>
            </>
          )}
        </>
      )}
      <Text sx={ { mt: '30px' } } variant="text.header.form">
        {t('fundraiser.Goal')}
      </Text>
      <Text>
        Fundraiser Profit Goal:
        {' '}
        {' '}
        {numeral(details?.DollarGoal).format(t('currency.format'))}
      </Text>
      <Text>
        Number of Sellers:
        {' '}
        {details?.EstimatedSellers}
      </Text>
      <Text>
        Individual Seller Kit Goal:
        {' '}
        {details?.SellerKitGoal}
      </Text>
      { details?.SalesAgentCode && (
        <>
          <Text sx={ { mt: '30px' } } variant="text.header.form">
            {t('fundraiser.SalesAgent')}
          </Text>
          <Text>
            Sales Agent Code:
            {' '}
            {details.SalesAgentCode}
          </Text>
        </>
      ) }
    </Box>
  );
};

export default FundraiserDetailsSummary;
