import React from 'react';
import {
  Box, Flex, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { HeaderImage } from './HeaderImage';
import { HeaderDescription } from './HeaderDescription';
import { HeaderInfo } from './HeaderInfo';

import { FundraiserListItem } from 'features/fundraiser/types/fundraiser';
import { Seller } from 'features/fundraiser';

export interface IDashboardHeader {
  fundraiser: FundraiserListItem;
  seller?: Seller;
  description: string;
}

const DashboardHeader: React.FC<IDashboardHeader> = ({ fundraiser, seller, description }) => {
  const [ t ] = useTranslation();
  return (
    <Flex data-testid="dashboard-header" sx={ { flexDirection: 'column', gap: '16px' } }>
      <Text variant="dashboard.title">
        { t('dashboard.header.fundraiserName',
          { context: fundraiser.CancellationDate && 'cancelled', name: fundraiser.Name }) }
      </Text>
      <Flex sx={ { flexDirection: [ 'column', 'row' ], gap: [ '16px', '32px' ], alignItems: [ 'center', 'start' ] } }>
        <HeaderImage fundraiser={ fundraiser } seller={ seller } />
        <Flex sx={ { flexDirection: 'column', gap: '16px' } }>
          <HeaderInfo fundraiser={ fundraiser } seller={ seller } />
          <Box sx={ { bg: 'primaryGrayLight', width: '100%', height: '1px' } } />
          <HeaderDescription description={ description } fundraiser={ fundraiser } seller={ seller } />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DashboardHeader;
