import React from 'react';
import { Flex, Link, Box } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _isEmpty from 'lodash/isEmpty';

import { IFeaturedFundraiserTracker } from '../types';

import { SellerInformation } from 'ui/fundraiser';
import { Graphs } from 'ui/Graphs';

const FeaturedFundraiserTrackerWithStats: React.FC<IFeaturedFundraiserTracker> = ({
  seller,
  fundraiserType,
  sellerStats,
}) => {
  const [ t ] = useTranslation();
  return (
    <Box
      data-testid="featured-fundraiser-tracker"
      sx={ {
        alignItems: 'center',
        display: 'grid',
        gap: [ '16px', '16px', '32px' ],
        gridTemplateColumns: [ 'auto', 'auto', 'auto 2px auto' ],
        gridTemplateRows: [ 'auto auto', 'auto auto', '1fr' ],
        justifyContent: 'center',
        maxWidth: '1216px',
      } }
    >
      <SellerInformation fundraiserType={ fundraiserType } seller={ seller } />
      <Box sx={ { display: [ 'none', 'none', 'block' ], bg: 'primaryGrayMid', height: '100%' } } />
      <Flex
        sx={ {
          flexDirection: 'column',
          gap: '16px',
          position: [ 'static', 'static', 'relative' ],
          py: [ 0, 0, '52px' ],
        } }
      >
        <Graphs graphStats={ sellerStats } isDashboard={ false } />
        <Link
          href={ t('home.featuredFundraiser.link.url') }
          sx={ {
            position: [ 'static', 'static', 'absolute' ],
            bottom: 0,
            left: '50%',
            mx: 'auto',
            transform: [ 'none', 'none', 'translateX(-50%)' ],
          } }
          variant="primaryOutlineButtonLink"
        >
          { t('home.featuredFundraiser.link.text') }
        </Link>
      </Flex>
    </Box>
  );
};

const FeaturedFundraiserTrackerWithoutStats: React.FC<IFeaturedFundraiserTracker> = ({
  seller,
  fundraiserType,
}) => {
  const [ t ] = useTranslation();
  return (
    <Flex
      data-testid="featured-fundraiser-tracker-without-stats"
      sx={ {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: [ '16px', '32px' ],
        maxWidth: '1216px',
      } }
    >
      <SellerInformation fundraiserType={ fundraiserType } seller={ seller } />
      <Link
        href={ t('home.featuredFundraiser.link.url') }
        sx={ { mx: 'auto' } }
        variant="primaryOutlineButtonLink"
      >
        { t('home.featuredFundraiser.link.text') }
      </Link>
    </Flex>
  );
};

export const FeaturedFundraiserTracker: React.FC<IFeaturedFundraiserTracker> = ({
  seller,
  fundraiserType,
  sellerStats,
}) => {
  if (!_isEmpty(sellerStats)) {
    return (
      <FeaturedFundraiserTrackerWithStats
        fundraiserType={ fundraiserType }
        seller={ seller }
        sellerStats={ sellerStats }
      />
    );
  } else {
    return (
      <FeaturedFundraiserTrackerWithoutStats
        fundraiserType={ fundraiserType }
        seller={ seller }
        sellerStats={ [] }
      />
    );
  }
};
