/* eslint-disable max-lines-per-function */
import React from 'react';
import numeral from 'numeral';
import {
  Box, Button, Flex, Spinner, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import { toast } from 'react-toastify';
import { X } from 'react-feather';

import { Step } from 'ui/common';
import { useFetchFinalOrderSummary } from 'features/fundraiser/hooks/useFetchFinalOrderSummary';
import {
  PaymentSummaryKeys,
  CouponDiscountsKeys,
  FinalOrderSummaryKeys,
} from 'features/fundraiser';
import { TextWithInlinePopoverButton } from 'ui/components';

export type FinalOrderSummaryProps = {
  fundraiserId: number;
  onNext: () => void;
  testId?: string;
}

type PaymentSummaryText = {
  text: string;
  popoverText: string;
  hasMinus: boolean;
}

type FinalOrderSummaryText = {
  text: string;
  popoverText?: string;
  hasMinus: boolean;
}

export const dataTestIdFinalOrderSummary = 'final-order-summary-component';

export const FinalOrderSummary: React.FC<FinalOrderSummaryProps> = ({
  fundraiserId,
  testId = dataTestIdFinalOrderSummary,
  onNext,
}) => {
  const { t } = useTranslation();
  const { data: orderSummary, isLoading, isError } = useFetchFinalOrderSummary(fundraiserId);

  let paymentSummaryGridAreas = '"PaymentSummary PaymentSummary PaymentSummary"';
  _forEach(PaymentSummaryKeys, key => paymentSummaryGridAreas += ` "${ key }Text ${ key }Minus ${ key }Value"`);

  let couponDiscountsGridAreas = '"CouponDiscounts CouponDiscounts CouponDiscounts"';
  _forEach(CouponDiscountsKeys, key => couponDiscountsGridAreas += ` "${ key }Text ${ key }Minus ${ key }Value"`);

  let finalSummaryGridAreas = '';
  _forEach(FinalOrderSummaryKeys, key => finalSummaryGridAreas += ` "${ key }Text ${ key }Minus ${ key }Value"`);

  if (isError) {
    toast.error(t('dashboard.finalOrderPage.summary.toast.fetchError'));
    //return null; //TODO: ADD error state
  }

  return (
    <Step title={ t('dashboard.finalOrderPage.summary.header') }>
      {isLoading || !orderSummary ? (
        <Flex
          data-testid={ `${ testId }-loading` }
          sx={ {
            alignItems: 'center',
            justifyContent: 'center',
            p: [ '16px', '32px' ],
            width: '100%',
          } }
        >
          <Spinner variant="lce" />
        </Flex>
      ) : (
        <Flex data-testid={ testId } sx={ { flexDirection: 'column', gap: [ '16px', '32px' ], p: [ '16px', '32px' ] } }>
          <Flex sx={ { flexDirection: 'column', gap: [ '8px', '16px' ] } }>
            <Text variant="dashboard.finalOrderPage.title">
              {t('dashboard.finalOrderPage.summary.profitSummary')}
            </Text>
            <Box
              sx={ {
                display: 'grid',
                gridTemplateColumns: '1fr auto auto',
                rowGap: [ '8px', '16px' ],
                columnGap: [ '8px', '32px' ],
                alignItems: 'center',
              } }
            >
              {_map(orderSummary.ProfitSummary, ({
                Name, KitsSold, ProfitPerKit, TotalProfit, PopoverText,
              }) => (
                <>
                  {PopoverText ? (
                    <TextWithInlinePopoverButton popoverText={ PopoverText } text={ Name } />
                  ) : (
                    <Text variant="dashboard.finalOrderPage.body">
                      {Name}
                    </Text>
                  )}
                  <Box
                    sx={ {
                      display: 'grid',
                      gridTemplateColumns: '1fr 16px 1fr',
                      gap: [ '8px', '16px' ],
                      alignItems: 'center',
                    } }
                  >
                    <Text sx={ { textAlign: 'right' } } variant="dashboard.finalOrderPage.body">
                      {KitsSold}
                    </Text>
                    <X size={ 16 } />
                    <Text variant="dashboard.finalOrderPage.body">
                      {numeral(ProfitPerKit).format(t('currency.format'))}
                    </Text>
                  </Box>
                  <Text sx={ { textAlign: 'right' } } variant="dashboard.finalOrderPage.body">
                    {numeral(TotalProfit).format(t('currency.format'))}
                  </Text>
                </>
              ))}
              <Text variant="dashboard.finalOrderPage.subLabel">
                {t('dashboard.finalOrderPage.summary.total')}
              </Text>
              <Box
                sx={ {
                  display: 'grid',
                  gridTemplateColumns: '1fr 16px 1fr',
                  gap: [ '8px', '16px' ],
                  alignItems: 'center',
                } }
              >
                <Text sx={ { textAlign: 'right' } } variant="dashboard.finalOrderPage.subLabel">
                  {orderSummary.ProfitSummaryTotal.TotalKitsSold}
                </Text>
              </Box>
              <Text sx={ { textAlign: 'right' } } variant="dashboard.finalOrderPage.subLabel">
                {numeral(orderSummary.ProfitSummaryTotal.TotalProfit).format(t('currency.format'))}
              </Text>
            </Box>
          </Flex>
          <Box
            sx={ {
              display: 'grid',
              gridTemplateColumns: '1fr auto auto',
              gap: [ '8px', '16px' ],
              alignItems: 'center',
              gridTemplateAreas:
               `${ paymentSummaryGridAreas }
                ". . ."
                ${ couponDiscountsGridAreas }
                ". . ."
                ${ finalSummaryGridAreas }`,
            } }
          >
            <Text sx={ { gridArea: 'PaymentSummary' } } variant="dashboard.finalOrderPage.title">
              {t('dashboard.finalOrderPage.summary.PaymentSummary')}
            </Text>

            {_map(PaymentSummaryKeys, (label) => {
              const {
                text, popoverText, hasMinus,
              }: PaymentSummaryText =
                t(`dashboard.finalOrderPage.summary.${ label }`, { returnObjects: true });
              return (
                <>
                  <TextWithInlinePopoverButton
                    popoverText={ popoverText }
                    sx={ { gridArea: `${ label }Text` } }
                    testId={ label }
                    text={ text }
                  />
                  {hasMinus && (
                    <Text sx={ { gridArea: `${ label }Minus` } } variant="dashboard.finalOrderPage.body">
                      { t('dashboard.finalOrderPage.summary.minus') }
                    </Text>
                  )}
                  <Text
                    sx={ { textAlign: 'right', gridArea: `${ label }Value` } }
                    variant="dashboard.finalOrderPage.body"
                  >
                    {numeral(orderSummary.PaymentSummary[label]).format(t('currency.format'))}
                  </Text>
                </>
              );
            })}

            <Text sx={ { gridArea: 'CouponDiscounts' } } variant="dashboard.finalOrderPage.title">
              {t('dashboard.finalOrderPage.summary.CouponDiscounts')}
            </Text>

            {_map(CouponDiscountsKeys, (label) => {
              const text = t(`dashboard.finalOrderPage.summary.${ label }`);
              return (
                <>
                  <Text sx={ { gridArea: `${ label }Text` } } variant="dashboard.finalOrderPage.body">
                    {text}
                  </Text>
                  <Text sx={ { gridArea: `${ label }Minus` } } variant="dashboard.finalOrderPage.body">
                    { t('dashboard.finalOrderPage.summary.minus') }
                  </Text>
                  <Text
                    sx={ { textAlign: 'right', gridArea: `${ label }Value` } }
                    variant="dashboard.finalOrderPage.body"
                  >
                    {numeral(orderSummary.CouponDiscounts[label]).format(t('currency.format'))}
                  </Text>
                </>
              );
            })}

            {_map(FinalOrderSummaryKeys, (label) => {
              const {
                text, popoverText, hasMinus,
              }: FinalOrderSummaryText =
                t(`dashboard.finalOrderPage.summary.${ label }`, { returnObjects: true });
              return (
                <>
                  {popoverText ? (
                    <TextWithInlinePopoverButton
                      iconHeight={ 18 }
                      popoverText={ popoverText }
                      sx={ { gridArea: `${ label }Text` } }
                      testId={ label }
                      text={ text }
                      textVariant="dashboard.finalOrderPage.subtitle"
                    />
                  ) : (
                    <Text sx={ { gridArea: `${ label }Text` } } variant="dashboard.finalOrderPage.subtitle">
                      { text }
                    </Text>
                  )}
                  {hasMinus && (
                    <Text sx={ { gridArea: `${ label }Minus` } } variant="dashboard.finalOrderPage.subtitle">
                      { t('dashboard.finalOrderPage.summary.minus') }
                    </Text>
                  )}
                  <Text
                    sx={ { textAlign: 'right', gridArea: `${ label }Value` } }
                    variant="dashboard.finalOrderPage.subtitle"
                  >
                    {numeral(orderSummary[label]).format(t('currency.format'))}
                  </Text>
                </>
              );
            })}
          </Box>
          <Button data-testId="next-order-summary-button" onClick={ onNext } sx={ { width: '100%' } }>
            {t('dashboard.finalOrderPage.summary.nextButton')}
          </Button>
        </Flex>
      )}
    </Step>
  );
};
