import { Stat } from '../types/stats';

export interface FormattedStat {
  title: string;
  value: string;
}

export const formatGraphStats = (graphStats: Stat[], isDashboard: boolean): FormattedStat[] => {
  const graphStatsFormatted: FormattedStat[] = [];
  graphStats.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
  graphStats.forEach((graph) => {
    let newTitle = `graphStats.${ graph.Key }`;
    let newValue = `${ graph.Value.toLocaleString() }`;
    if (graph.Key === 'TotalProfitRaisedBySeller' || graph.Key === 'TotalProfitRaisedByFundraiser') {
      newValue = `$${ newValue }`;
    }
    if (graph.Key === 'PercentOfGoalReached') {
      newValue = `${ newValue }%`;
    }
    if (graph.Key === 'DaysLeftInFundraiser') {
      newTitle = `${ newTitle }${ isDashboard ? 'Sell' : 'Buy' }`;
    }
    if (!(graph.Key === 'TotalProfitRaisedBySeller' && !isDashboard)) {
      graphStatsFormatted.push({ title: newTitle, value: newValue });
    }
  });
  return graphStatsFormatted;
};
