import React from 'react';
import { ChevronLeft } from 'react-feather';

import { RedirectAwareLink } from 'ui/common/RedirectAwareLink';

export type BackToDashboardProps = {
  pathname: string;
  text: string;
  testId?: string;
}

export const dataTestIdBackToDashboard = 'back-to-dashboard-component';

export const BackToDashboard: React.FC<BackToDashboardProps> = ({
  pathname,
  text,
  testId = dataTestIdBackToDashboard,
}) => (
  <RedirectAwareLink
    data-testid={ testId }
    pathname={ pathname }
    sx={ {
      display: 'flex',
      alignItems: 'center',
      color: 'primaryOrange',
      fontWeight: 'bold',
      ml: [ 0, 0, '-32px' ],
    } }
  >
    <ChevronLeft color="#ff671b" height="32px" width="32px" />
    {text}
  </RedirectAwareLink>
);
