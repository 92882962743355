import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { Button as SliceButton } from '@lce/slice_v2';

export interface SubmitButtonProps {
  children: string;
  sx?: ThemeUIStyleObject;
  testId?: string;
  variant?: string;
  disabled?: boolean;
}

export const dataTestIdSubmitButton = 'submit-button-component';

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  children,
  testId = dataTestIdSubmitButton,
  sx = {},
  variant = 'primary',
  ...props
}) => (
  <SliceButton
    data-testid={ testId }
    sx={ sx }
    type="submit"
    variant={ variant }
    { ...props }
  >
    {children}
  </SliceButton>
);
