import _some from 'lodash/some';
import _includes from 'lodash/includes';

import { FundraiserListItem } from '../types';
import { ELEVATED_RELATIONSHIPS } from '../types/fundraiser';

export const isUserElevatedRelationship = (fundraiser: FundraiserListItem | undefined): boolean => {
  if (fundraiser) {
    return _some(ELEVATED_RELATIONSHIPS, relationship => _includes(fundraiser.Relationships, relationship));
  } else {
    return false;
  }
};

