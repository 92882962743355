import React from 'react';
import { Box, Image } from '@lce/slice_v2';

import Brochure from 'assets/images/banners/brochure-landing.jpg';
import EarlyFall24 from 'assets/images/banners/earlyfall2024.png';
import KidsBack2School from 'assets/images/banners/kids-back2school.jpg';
import Corporate from 'assets/images/banners/corporate-banner.png';
import GoogleImage from 'assets/images/banners/google-banner.png';
import FundraisingOptions from 'assets/images/banners/fundraising-options.jpg';
import SportsEngineImage from 'assets/images/banners/sportsengine-banner.png';
import halloween2022 from 'assets/images/banners/halloween2022.png';
import SupportASeller from 'assets/images/caesar-supportaseller.png';
import Sports from 'assets/images/banners/sports-banner.jpg';
import Daycare from 'assets/images/banners/daycare-banner.jpg';
import School from 'assets/images/banners/school-banner.jpeg';
import Music from 'assets/images/banners/music-banner.jpeg';
import Dance from 'assets/images/banners/dance-banner.jpeg';
import PizzaKitFundraiser from 'assets/images/banners/pizza-kit-fundraiser-banner.jpeg';
import Cheer from 'assets/images/banners/cheer-banner.jpeg';
import GetStarted from 'assets/images/banners/get-started.png';
import MealDeal from 'assets/images/banners/meal-deal.png';
import SuperBowl from 'assets/images/banners/superbowl.png';
import EClub from 'assets/images/banners/eclub.png';
import Spring from 'assets/images/banners/Spring 2024 Landing Page Image.png';
import Fall from 'assets/images/banners/fall.png';
export interface IPageProp {
  page: string;
}

const ImageObj = {
  brochure: Brochure,
  bts: School,
  btsmailer: GetStarted,
  campaign: FundraisingOptions,
  cheer: Cheer,
  corporate: Corporate,
  dance: Dance,
  daycare: Daycare,
  earlyfall24: EarlyFall24,
  earlyspring2024: GetStarted,
  eclub: EClub,
  fall: Fall,
  getstarted: GetStarted,
  halloween22mailer: halloween2022,
  lcwebsite: MealDeal,
  localstore: MealDeal,
  mealdeal: MealDeal,
  music: Music,
  'online-pizza-fundraiser': GoogleImage,
  'pizza-kit-fundraiser': PizzaKitFundraiser,
  ptotoday: FundraisingOptions,
  school: School,
  signup: KidsBack2School,
  'sports-engine': SportsEngineImage,
  sports: Sports,
  spring: Spring,
  superbowl: SuperBowl,
  supportaseller: SupportASeller,
  expiredfundraiser: SupportASeller,
  watarticle: School,
  'we-are-teachers': FundraisingOptions,
};

export type LandingImageProps = {
  page: string;
  testId?: string;
}

export const dataTestIdLandingImage = 'landing-image-component';

export const LandingImage: React.FC<LandingImageProps> = ({
  page,
  testId = dataTestIdLandingImage,
}) => {
  const BannerImage = ImageObj[page];

  return (
    <Box
      data-testid={ testId }
      sx={ {
        m: '0 auto 32px',
        maxWidth: '992px', mb: [ 6, 8 ], textAlign: 'center',
      } }
    >
      <Image alt="" src={ BannerImage } sx={ { maxHeight: '400px' } } />
    </Box>
  );
};
