import {
  Text, Image, Link,
} from '@lce/slice_v2';
import React from 'react';

interface IFundraiserOptionIconProps {
  image: string;
  title: string;
  subtitle: string;
  url: string;
}

// eslint-disable-next-line max-lines-per-function
export const FundraiserOptionIcon: React.FC<IFundraiserOptionIconProps> = ({
  image, title, subtitle, url,
}) => (
  <Link
    href={ url }
    sx={ {
      textDecoration: 'none',
      pointerEvents: 'none',
      alignItems: [ 'center', 'start' ],
      backgroundColor: [ 'white', 'transparent' ],
      borderRadius: [ '10px', '0' ],
      display: 'grid',
      gap: [ '16px', '16px', '32px' ],
      gridTemplateColumns: [ '1fr 3fr', 'auto' ],
      gridTemplateRows: [ 'auto', 'auto 1fr' ],
      justifyItems: 'center',
      p: [ '16px', '0' ],
      '-webkit-tap-highlight-color': 'transparent',
    } }
  >
    <Link data-testid={ `${ title }-link` } href={ url } sx={ { lineHeight: 0 } }>
      <Image
        alt=""
        src={ image }
        sx={ {
          height: '100%',
          width: '100%',
          maxHeight: [ '114px', '114px', '164px' ],
          maxWidth: [ '114px', '114px', '164px' ],
          pointerEvents: 'auto',
        } }
        title={ title }
      />
    </Link>
    <Link
      href={ url }
      sx={ {
        pointerEvents: 'none',
        display: 'flex',
        textAlign: [ 'left', 'center' ],
        flexDirection: 'column',

      } }
      variant="primaryText"
    >
      <Text
        sx={ {
          '&:hover': {
            textDecoration: 'underline',
          },
          pointerEvents: 'auto',
        } }
        variant="home.card.title"
      >
        { title }
      </Text>
      <Text
        sx={ {
          color: 'black', fontWeight: 'bold', marginTop: '8px', pointerEvents: [ 'auto', 'none' ],
        } } variant="home.card.description"
      >
        { subtitle }
      </Text>
    </Link>
  </Link>
);
