import React, { useCallback, useEffect, useState } from 'react';
import {
  Form, Flex, Spinner, Text, Input,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { toast } from 'react-toastify';

import { Step, GenericToast } from 'ui/common';
import { useFetchFundraiserOrderSummary, useCreateFinalOrder } from 'features/fundraiser/hooks';
import { FundraiserListItem } from 'features/fundraiser/types';
import { SubmitButton } from 'ui/components';
import { useSafeDateFormat } from 'ui/hooks';

import './final-order-form.css';

export type FinalOrderFormProps = {
  fundraiser: FundraiserListItem;
  testId?: string;
}

export const dataTestIdFinalOrderForm = 'final-order-form-component';

export type ManualOrderTotal= {
  productsTotal: Record<number, number>;
  total: number;
}

// eslint-disable-next-line max-lines-per-function
export const FinalOrderForm: React.FC<FinalOrderFormProps> = ({
  fundraiser,
  testId = dataTestIdFinalOrderForm,
}) => {
  const [ brochureOrderCount, setBrochureOrderCount ] = useState<ManualOrderTotal>({ productsTotal: {}, total: 0 });
  const [ showMinOrderError, setShowMinOrderError ] = useState<boolean>(false);
  const [ disableSubmit, setDisableSubmit ] = useState<boolean>(false);
  const [ fundraiserMeetsMinimumKitCount, setFundraiserMeetsMinimumKitCount ] = useState(true);
  const [ t ] = useTranslation();
  const {
    data: orderSummary,
    isLoading, isError: isErrorFetchingOrderSummary,
  } = useFetchFundraiserOrderSummary(fundraiser.Id);
  const { mutateAsync: createFinalOrderMutation } = useCreateFinalOrder();

  useEffect(() => {
    const totalOrderCount = brochureOrderCount.total + (orderSummary?.OnlineKitTotal || 0);
    const meetsMinimumCount = !!orderSummary?.minimumDeliveryKit &&
      totalOrderCount >= orderSummary?.minimumDeliveryKit;
    setFundraiserMeetsMinimumKitCount(meetsMinimumCount);
    if (fundraiserMeetsMinimumKitCount) {
      setDisableSubmit(false);
    }
  // eslint-disable-next-line max-len
  }, [ brochureOrderCount, fundraiserMeetsMinimumKitCount, orderSummary, setDisableSubmit, setFundraiserMeetsMinimumKitCount ]);

  const deliveryEndTime = useSafeDateFormat(orderSummary?.DeliveryEndTime);
  const deliveryStartTime = useSafeDateFormat(orderSummary?.DeliveryStartTime);

  const onSubmit = useCallback(async() => {
    if (!fundraiserMeetsMinimumKitCount) {
      setShowMinOrderError(true);
      setDisableSubmit(true);
      return;
    }
    setDisableSubmit(false);
    setShowMinOrderError(false);
    const data = {
      fundraiserId: fundraiser.Id,
      kitOrders: orderSummary?.KitOrders.map(kitOrder => ({
        ...kitOrder,
        BrochureQuantity: brochureOrderCount.productsTotal[kitOrder.Id],
      })) ?? [],
    };

    try {
      await createFinalOrderMutation(data);
      toast(<GenericToast text={ t('dashboard.finalOrderPage.form.toast.success') } />);
    } catch (error) {
      toast.error(t('dashboard.finalOrderPage.form.toast.error'));
    }
  }, [ fundraiserMeetsMinimumKitCount, orderSummary, brochureOrderCount, fundraiser, createFinalOrderMutation, t ]);

  const onProductQuantityChange = useCallback((e) => {
    const { name, value } = e.target;
    const numericValue = value === '' ? '0' : value;
    brochureOrderCount.productsTotal[name] = parseInt(numericValue, 10);
    brochureOrderCount.total = Object.values(brochureOrderCount.productsTotal).reduce((acc, val) => acc + val, 0);
    setBrochureOrderCount({ ...brochureOrderCount });
  }, [ brochureOrderCount ]);

  if (isErrorFetchingOrderSummary) {
    toast.error(t('dashboard.finalOrderPage.form.toast.fetchError'));
    return null;
  }

  if (isLoading) {
    return (
      <Flex
        data-testid={ testId }
        sx={ {
          alignItems: 'center', height: '100vh', justifyContent: 'center', width: '100%',
        } }
      >
        <Spinner
          variant="lce"
        />
      </Flex>
    );
  }

  return (
    <Flex data-testid={ testId } sx={ { gap: '12px', flexDirection: 'column' } }>
      <Text sx={ { fontWeight: 'bold' } }>
        { t('dashboard.finalOrderPage.deliveryRange', { deliveryEndTime, deliveryStartTime }) }
      </Text>
      <Step title={ t('dashboard.finalOrderPage.form.header') }>
        <Form onSubmit={ onSubmit }>
          <table className="final-order-table">
            <thead className="final-order-table__header">
              <tr>
                <th />
                <th>
                  {t('dashboard.finalOrderPage.form.headings.product')}
                </th>
                <th>
                  {t('dashboard.finalOrderPage.form.headings.onlineQuantity')}
                </th>
                <th>
                  {t('dashboard.finalOrderPage.form.headings.brochureQuantity')}
                </th>
              </tr>
              <tr>
                <th className="final-order-table__header-cell" colSpan={ 4 } />
              </tr>
            </thead>
            <tbody>
              { orderSummary?.KitOrders.map(({
                Id, Code, Color, Price, Name, OnlineQuantity,
              }) => (
                <tr key={ Id }>
                  <td>
                    <Flex
                      sx={ {
                        backgroundColor: Color,
                        color: 'secondary',
                        display: 'inline-flex',
                        height: '30px',
                        width: '30px',
                        borderRadius: '5px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0 5px 0 0',
                      } }
                    >
                      <Text>
                        {Code}
                      </Text>
                    </Flex>
                  </td>
                  <td>
                    {Name}
                    {' '}
                    (
                    {Code}
                    ): $
                    {Price}
                  </td>
                  <td>
                    {OnlineQuantity}
                  </td>
                  <td>
                    <Input
                      defaultValue={ 0 } min={ 0 } name={ Id.toString() } onChange={ onProductQuantityChange }
                      type="number"
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td className="final-order-table__footer-cell" colSpan={ 4 } />
              </tr>
              <tr>
                <td />
                <td>
                  <Text sx={ { fontWeight: 'bold' } }>
                    {t('dashboard.finalOrderPage.form.total')}
                  </Text>
                </td>
                <td>
                  <Text sx={ { fontWeight: 'bold' } }>
                    {orderSummary?.OnlineKitTotal}
                  </Text>
                </td>
                <td>
                  <Text data-testid={ `${ dataTestIdFinalOrderForm }-total` } sx={ { fontWeight: 'bold' } }>
                    {brochureOrderCount.total}
                  </Text>
                </td>
              </tr>
              <tr>
                <td colSpan={ 4 }>
                  <SubmitButton
                    data-testid={ `${ dataTestIdFinalOrderForm }-submit-btn` }
                    disabled={ disableSubmit }
                    sx={ { width: '100%' } } variant={ disableSubmit ? 'disabled' : 'primary.large' }
                  >
                    {t('dashboard.finalOrderPage.form.submitButton')}
                  </SubmitButton>
                </td>
              </tr>
            </tbody>
            {showMinOrderError && (
              <tfoot>
                <tr>
                  <td colSpan={ 4 }>
                    <Text
                      data-testid={ `${ dataTestIdFinalOrderForm }-min-error-text` }
                      sx={ {
                        fontFamily: 'roboto', fontSize: '14px', color: 'gray', px: '8px',
                      } }
                    >
                      {`The minimum for delivery is ${ orderSummary?.minimumDeliveryKit } kits. 
                      Please adjust your totals or call us at 1-888-452-5487.`}
                    </Text>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </Form>
      </Step>
    </Flex>
  );
};
