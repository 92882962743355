import React from 'react';
import { Button } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

interface IProvideAddressBannerProps {
  onClick: () => void;
}

const ProvideAddressBanner: React.FC<IProvideAddressBannerProps> = ({ onClick }) => {
  const [ t ] = useTranslation();

  return (
    <Button
      onClick={ onClick }
      sx={ {
        borderRadius: '0',
        fontSize: '24px',
        fontWeight: 'bold',
        height: '100%',
        lineHeight: '1.5',
        minHeight: '59px',
        p: [ 2, 0 ],
        textDecoration: 'underline',
        width: '100%',
      } }
    >
      {t('fundraiser.ProvideMailingAddressBanner')}
    </Button>
  );
};

export default ProvideAddressBanner;
