import { FundraiserType, Seller, Stat } from 'features/fundraiser';

export interface IFeaturedProductCard {
  imageSrc: string;
  name: string;
  description: string;
  url: string;
}

export interface IFeaturedFundraiserTracker {
  seller: Seller;
  fundraiserType: FundraiserType;
  sellerStats: Stat[];
}

export type HomepageDataType = {
  featuredFundraiser: IFeaturedFundraiserTracker;
  featuredProducts: IFeaturedProductCard[];
}

export type ProgramPoints = {
  text: string;
  fundraiserPoints: boolean[];
}

export const fundraiserTypes: FundraiserType[] = [
  FundraiserType.Brochure,
  FundraiserType.Digital,
  FundraiserType.MealDeal,
];
