/* eslint-disable max-lines-per-function */
import * as React from 'react';
import {
  Box, Text, Flex, Image, Link,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';

import { ProgramPoints, fundraiserTypes } from '../../../home/types';

import PizzaSlice from './assets/pizzaslice.png';
import LceSpear from './assets/lcespear.svg';

const FundraiserComparison: React.FC = () => {
  const [ t ] = useTranslation();
  const programPoints: ProgramPoints[] = t('startAFundraiser.fundraiserComparison.programPoints',
    { returnObjects: true });

  const programPointsCount = programPoints.length;

  let gridTemplateAreaDesktop = '"title spear0 titleBR spear1 titleDG spear2 titleMD" ';
  let gridTemplateAreaMobile = '"title titleBR titleDG titleMD" ';

  for (let i = 0;i < programPointsCount;i++) {
    gridTemplateAreaDesktop += `"pointTitle${ i } spear0 point0${ i } spear1 point1${ i } spear2 point2${ i }"`;
    gridTemplateAreaMobile += `"pointTitle${ i } point0${ i } point1${ i } point2${ i }"`;
  }
  gridTemplateAreaDesktop += '". spear0 . spear1 . spear2 ."';

  const gridTemplateArea = [ gridTemplateAreaMobile, gridTemplateAreaMobile, gridTemplateAreaDesktop ];

  const fundraiserCount = fundraiserTypes.length;

  return (
    <Flex
      data-testid="fundraiser-comparision"
      sx={ {
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0px 0px 4px 1px rgba(0,0,0,0.15)',
        flexDirection: 'column',
        gap: [ '32px', '48px' ],
        maxWidth: '1280px',
        p: [ '16px', '32px', '64px' ],
        transition: 'all 500ms ease-in-out',
        width: '100%',
      } }
    >
      <Box
        sx={ {
          alignItems: 'center',
          columnGap: [ '8px', '16px' ],
          display: 'grid',
          gridTemplateAreas: gridTemplateArea,
          gridTemplateColumns: [
            `repeat(calc(${ fundraiserCount }+1), auto)`,
            `repeat(calc(${ fundraiserCount }+1), auto)`,
            `auto repeat(${ fundraiserCount }, 11px 1fr)`,
          ],
          gridTemplateRows: [
            `repeat(calc(${ programPointsCount }+1), 1fr)`,
            `repeat(calc(${ programPointsCount }+1), 1fr)`,
            `1fr repeat(${ programPointsCount }, 1fr) 0px`,
          ],
          rowGap: '16px',
        } }
      >
        {_map(fundraiserTypes, (type, index) => (
          <>
            <Image src={ LceSpear } sx={ { gridArea: `spear${ index }`, display: [ 'none', 'none', 'block' ] } } />
            <Text sx={ { gridArea: `title${ type }` } } variant="fundraiserComparison.slantedScript">
              {t('startAFundraiser.fundraiserComparison.fundraiserType', { context: type })}
            </Text>
          </>
        ))}
        {programPoints.map(({ text, fundraiserPoints }, index) => (
          <>
            <Text key={ text } sx={ { gridArea: `pointTitle${ index }` } } variant="fundraiserComparison.pointsLabel">
              {text}
            </Text>
            {_map(fundraiserPoints, (point, pointIndex) => point && (
              <Image
                src={ PizzaSlice }
                sx={ {
                  width: [ '20px', '32px' ],
                  height: [ '20px', '30px' ],
                  justifySelf: 'center',
                  gridArea: `point${ pointIndex }${ index }`,
                } }
              />
            ))}
          </>
        ))}
      </Box>
      <Flex sx={ { flexDirection: 'column', gap: [ '8px', '8px', '16px' ], textAlign: 'center' } }>
        <Text variant="fundraiserComparison.inLineLink">
          {t('startAFundraiser.fundraiserComparison.contactText')}
          <Link
            aria-label="Contact Number"
            as="span"
            data-testid="fundraiser-program-contact-number"
            href={ `tel:${ t('startAFundraiser.fundraiserComparison.contactNumber') }` }
            sx={ {
              fontSize: [ '20px', '24px' ],
              textWrap: 'nowrap',
              ml: 2,
              '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
              },
            } }
            variant="primaryText"
          >
            {t('startAFundraiser.fundraiserComparison.contactNumber')}
          </Link>
        </Text>
        <Text variant="disclaimer">
          {t('startAFundraiser.fundraiserComparison.disclaimer')}
        </Text>
      </Flex>
    </Flex>
  );
};

export default FundraiserComparison;
