/* eslint-disable max-lines-per-function */
import React from 'react';
import numeral from 'numeral';
import _find from 'lodash/find';
import { Box, Flex } from '@lce/slice_v2';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from '@lce/i18n';

import {
  useIsUserElevatedRelationship,
  useFetchChairpersonDashboardStatistics,
  useFetchFundraiserById,
  useFetchSeller,
  useFetchSellerByFundraiserId,
} from 'features/fundraiser';
import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';
import { ResourceHub } from 'ui/fundraiser/ResourceHub';
import { formatFundraiserDateRange, htmlDecode } from 'ui/fundraiser/ResourceHub/utils';
import { useFetchProductsPageRoute } from 'features/products';

interface MatchParams {
  fundraiserId: string;
  customerId?: string;
}

type ResourceHubPageProps = RouteComponentProps<MatchParams>

const ResourceHubPage: React.FC<ResourceHubPageProps> = ({ match }) => {
  const [ t ] = useTranslation();
  const { data: fundraiser, isLoading: isFundraiserLoading } =
    useFetchFundraiserById(parseInt(match.params.fundraiserId));
  const fundraiserId = parseInt(match.params.fundraiserId) || 0;
  const customerId = match.params.customerId ? parseInt(match.params.customerId) : 0;
  const { data: sellers } = useFetchSellerByFundraiserId(fundraiser?.Id.toString() || '');
  const isChairpersonResourceHub = match.url.endsWith(`/fundraisers/${ fundraiserId }/resource-hub`);
  const { data: seller } = useFetchSeller(fundraiserId, customerId);
  const { data: chairpersonStats } = useFetchChairpersonDashboardStatistics(fundraiser?.Id || 0);

  const joinFundraiserLink =
    htmlDecode(`${ window.location.origin }/login/join-fundraiser/${ fundraiser?.FundraiserGuid }`);

  const totalKitsSold = _find(chairpersonStats, stat => stat.Key === 'TotalKitsSoldByFundraiser')?.Value;
  const fundraiserProfit = _find(chairpersonStats, stat => stat.Key === 'TotalProfitRaisedByFundraiser')?.Value;
  const formattedProfit = numeral(fundraiserProfit).format(t('currency.dollarGoal'));

  const sellerSupportGuid = isChairpersonResourceHub
    ? _find(sellers,
      seller => seller.CustomerId === fundraiser?.ChairpersonCustomerId)?.SupportGuid
    : seller?.SupportGuid;

  const productPageRoute = useFetchProductsPageRoute(sellerSupportGuid, fundraiser?.FundraiserType);
  const buyerLink = sellerSupportGuid
    ? `${ window.location.origin }${ productPageRoute }`
    : `${ window.location.origin }/login/join-fundraiser/${ fundraiser?.FundraiserGuid }`;

  const dateRange = formatFundraiserDateRange(fundraiser?.StartDate, fundraiser?.EndDate);

  const userIsChairperson = useIsUserElevatedRelationship(fundraiser);

  const fundraiserDescription = fundraiser?.CustomMessage ? htmlDecode(`[${ fundraiser?.CustomMessage }]. `) : '';

  const resourceHubData =
    t(`ResourceHub.${ fundraiser?.FundraiserType }`, {
      dateRange,
      fundraiserDescription,
      joinFundraiserLink,
      totalKitsSold,
      formattedProfit,
      buyerLink,
      fundraiserName: fundraiser?.Name,
      returnObjects: true,
    });

  if (isFundraiserLoading) {
    return null;
  }

  if (!isFundraiserLoading && isChairpersonResourceHub && !userIsChairperson) {
    return <Redirect to="/dashboard/fundraisers" />;
  }

  return (
    <>
      <Flex sx={ { flex: 1 } }>
        <Helmet>
          <title>Resource Hub | Little Caesars Fundraising</title>
        </Helmet>
      </Flex>
      <Box sx={ { flex: 1 } }>
        <FundraiserNavigation fundId={ match.params.fundraiserId } url={ match.url } />
        {userIsChairperson && isChairpersonResourceHub ? (
          <ResourceHub
            data={ resourceHubData }
            fundraiser={ fundraiser }
            fundraiserRole="chairperson"
            sellerSupportGuid={ sellerSupportGuid }
          />
        ) : (
          <ResourceHub
            data={ resourceHubData }
            fundraiser={ fundraiser }
            fundraiserRole="seller"
            sellerSupportGuid={ sellerSupportGuid }
          />
        )}
      </Box>
    </>
  );
};

export default ResourceHubPage;
