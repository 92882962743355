import {
  Box, Headline, Text,
} from '@lce/slice_v2';
import React from 'react';

export interface IGraph {
  value: string;
  title: string;
  isDashboard: boolean;
}

const Graph: React.FC<IGraph> = ({ value, title, isDashboard }) => {
  const size = isDashboard ? '155px' : [ '112px', '124px' ];
  const headerFontSize = isDashboard ? '40px' : [ '24px', '28px' ];
  const bodyFontSize = isDashboard ? '16px' : '12px';

  if (!value || !title) {
    return null;
  }

  return (
    <Box
      data-testid={ `graph-${ title }-${ value }` }
      sx={ {
        border: 'dashed 2px #ff671b',
        borderRadius: '50%',
        display: 'grid',
        gap: '4px',
        gridTemplateRows: '4fr 3fr',
        height: size,
        textAlign: 'center',
        width: size,
      } }
    >
      <Headline as="h2" sx={ { fontSize: headerFontSize, fontWeight: 900, alignSelf: 'end' } }>
        {value}
      </Headline>
      <Text sx={ { fontSize: bodyFontSize, fontWeight: 500 } }>
        {title}
      </Text>
    </Box>
  );
};

export default Graph;
