/* eslint-disable max-len */
/* eslint-disable no-extra-parens */
import { IOption } from 'pages/dashboard/reports/reportFilters/IOption';
import { IReportFilterFormData } from 'pages/dashboard/reports/reportFilters/ReportFilters';

const resolveReportFormat = (reportFormat) => {
  if (reportFormat === 'xlsx') {
    return 'xlsx';
  }
  return 'xlsx';
};

const resolveStatuses = (data): string[] | null => {
  const res: string[] | null = [];

  if (data.statusAll) {
    return null;
  } else {
    if (data.statusActive) {
      res.push('active');
    }
    if (data.statusCancelled) {
      res.push('canceled');
    }
    if (data.statusCompleted) {
      res.push('completed');
    }
    if (data.statusUpcoming) {
      res.push('upcoming');
    }
  }
  return res;
};

const resolveSelection = (dependentSelector, dependentValue: IOption[]) => {
  if (dependentSelector) {
    return dependentValue.map(({ value }) => value);
  }
  return null;
};

export const Translate = (formValues: IReportFilterFormData, defaultState: IReportFilterFormData) => {
  const translatedObj = formValues;
  translatedObj.reportFormat = resolveReportFormat(formValues.reportFormat);
  translatedObj.reportStatuses = resolveStatuses(formValues);
  translatedObj.rangeStartDate = formValues.rangeStartDate ? (formValues.rangeStartDate as Date).toISOString() : null;
  translatedObj.rangeEndDate = formValues.rangeEndDate ? (formValues.rangeEndDate as Date).toISOString() : null;
  translatedObj.statesSelection = resolveSelection(formValues.statesSelector !== defaultState.statesSelector, formValues.statesSelection as never);
  translatedObj.salesRepsSelection = resolveSelection(formValues.salesRepsSelector !== defaultState.salesRepsSelector, formValues.salesRepsSelection as never);
  translatedObj.iscsSelection = resolveSelection(formValues.iscsSelector !== defaultState.iscsSelector, formValues.iscsSelection as never);
  return translatedObj;
};
