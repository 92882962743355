/* eslint-disable max-len */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React from 'react';
import { Box, Flex, Spinner } from '@lce/slice_v2';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  FundraiserType, useIsUserElevatedRelationship, useFetchFundraiserById, useFetchSeller, useFetchSellerDashboardStatistics,
} from 'features/fundraiser';
import { Graphs, GraphsSkeleton } from 'ui/Graphs';
import { DashboardHeader } from 'ui/fundraiser/DashboardHeader';
import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';
import { SellerSteps } from 'ui/fundraiser/SellerSteps';
import { useIsUpcomingFundraiser } from 'features/fundraiser/hooks/useIsUpcomingFundraiser';
import { BackToDashboard } from 'ui/components';
import { SellerProgress } from 'ui/fundraiser';

export interface MatchParams {
  fundraiserId: string;
  customerId: string;
}

export type SellerDetailMode = 'preview' | 'edit';

export type ISellerDetailProps = RouteComponentProps<MatchParams>;

const SellerDetail: React.FC<ISellerDetailProps> = ({ match }) => {
  const [ t ] = useTranslation();
  const fundraiserId = parseInt(match.params.fundraiserId);
  const customerId = parseInt(match.params.customerId);
  const {
    data: seller, isLoading: isSellerLoading, isError: isSellerError, error: sellerError,
  } = useFetchSeller(fundraiserId, customerId);
  const {
    data: fundraiser, isLoading: isFundraiserLoading, isError: isFundraiserError, error: fundraiserError,
  } = useFetchFundraiserById(fundraiserId);
  const { data: sellerStats, isLoading: isSellerStatsLoading } = useFetchSellerDashboardStatistics(seller?.Id || 0);
  const isFundraiserUpcoming = useIsUpcomingFundraiser(fundraiser);
  const isFundraiserCancelled = !!fundraiser?.CancellationDate;
  const showChart = !(isFundraiserCancelled || isFundraiserUpcoming);

  const userCanViewChairpesonDashboard = useIsUserElevatedRelationship(fundraiser);

  const showBackToSellers = fundraiser && userCanViewChairpesonDashboard;

  if (isSellerError && sellerError && sellerError.Status === 404) {
    toast.error(t('dashboard.toast.sellerError'));
    return <Redirect to="/404" />;
  }
  if (isFundraiserError && fundraiserError && fundraiserError.Status === 404) {
    toast.error(t('dashboard.toast.fundraiserError'));
    return <Redirect to="/404" />;
  }

  if (isSellerError && sellerError && sellerError.Status === 403) {
    toast.error(t('dashboard.toast.sellerError'));
    return <Redirect to="/403" />;
  }
  if (isFundraiserError && fundraiserError && fundraiserError.Status === 403) {
    toast.error(t('dashboard.toast.fundraiserError'));
    return <Redirect to="/403" />;
  }

  if ((isSellerError || !seller) && !isSellerLoading) {
    toast.error(t('dashboard.toast.sellerError'));
  }
  if ((isFundraiserError || !fundraiser) && !isFundraiserLoading) {
    toast.error(t('dashboard.toast.fundraiserError'));
  }

  return (
    <Box data-testid="seller-detail-page">
      <FundraiserNavigation customerId={ `${ customerId }` } fundId={ `${ fundraiserId }` } url={ match.url } />

      <Flex sx={ { flexDirection: 'column', gap: [ '16px', '32px' ] } }>
        <BackToDashboard
          pathname={ showBackToSellers
            ? `/dashboard/fundraisers/manage-sellers/${ fundraiserId }`
            : '/dashboard/fundraisers' }
          text={ showBackToSellers ? t('dashboard.BackToAllSellers') : t('dashboard.BackToFundraisers') }
        />
        {seller && fundraiser && !isSellerLoading && !isFundraiserLoading ? (
          <>
            {fundraiser.FundraiserType === FundraiserType.Brochure &&
              <SellerProgress actual={ fundraiser.TotalRaised || 0 } goal={ fundraiser.DollarGoal } />}

            <DashboardHeader description={ seller.Description } fundraiser={ fundraiser } seller={ seller } />

            {showChart && (
              isSellerStatsLoading
                ? <GraphsSkeleton isDashboard={ true } />
                : sellerStats && <Graphs graphStats={ sellerStats } isDashboard={ true } testId="seller-stats" />
            )}

            { !fundraiser.IsExpired && !isFundraiserCancelled &&
              <SellerSteps customerId={ customerId } fundraiser={ fundraiser } sellerId={ seller.Id } supportGuid={ seller.SupportGuid } />}
          </>
        ) : <Spinner variant="lce" />}
      </Flex>
    </Box>
  );
};

export default SellerDetail;
